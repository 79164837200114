































































































































































































import { createComponent, computed, inject, onMounted } from "@vue/composition-api";
import DisplayUIKey from "@/components/display-ui-key";
import UserKey from "@/components/user-key";
import router from "@/router";

export default createComponent({
  setup() {
    const displayui = inject(DisplayUIKey);
    if (!displayui) {
      throw new Error(`${DisplayUIKey} is not provided`);
    }
    const user = inject(UserKey);
    if (!user) {
      throw new Error(`${UserKey} is not provided`);
    }
    const leaveDrawer = displayui.leaveDrawer;
    const login_status = computed(() => user.login_status);
    const customerInfo = computed(() => user.customerInfo);
    const submitLogout = () => {
      displayui.leaveDrawer;
      user.logout();
    };
    const toMember = () => {
      leaveDrawer();
    }
    const currentPath = router.currentRoute.path;

    const notice_count = computed(() => user.notice_count);

    // ポイ活リリース
    let isShowPoiKatsu = true;

    onMounted(() => {

    });

    return {
      leaveDrawer,
      login_status,
      submitLogout,
      toMember,
      currentPath,
      notice_count,
      customerInfo,
      isShowPoiKatsu
    };
  },
});
