




















































































































































































import {
  createComponent,
  computed,
  inject,
  onMounted,
} from "@vue/composition-api";
import DisplayUIKey from "@/components/display-ui-key";
import UserKey from "@/components/user-key";
import _ from "lodash";
export default createComponent({
  setup() {
    const user = inject(UserKey);
    if (!user) {
      throw new Error(`${UserKey} is not provided`);
    }
    const displayui = inject(DisplayUIKey);
    if (!displayui) {
      throw new Error(`${DisplayUIKey} is not provided`);
    }

    const jobpayMemberLoginUrl = process.env.VUE_APP_JOBPAY_MEMBER_SITE_URL_BASE + "/login/";
    const jobpayMemberInfoEditUrl = process.env.VUE_APP_JOBPAY_MEMBER_SITE_URL_BASE + "/login/"; // 現状はログイン画面指定

    const customerInfo = computed(() => user.customerInfo);
    const bankAccount = computed(() => user.bankAccount);
    const getBankAccount = user.getBankAccount;
    const dateOfBirth = computed(()=> {
      const birthday = user.customerInfo.birth_ymd
      return birthday.substr(0, 4) + '/' + birthday.substr(4, 2) + '/' + birthday.substr(6, 2);
    })

    let jobpayReEntryUrl = "";
    if(user.customerInfo.jobpay_entry_mail_address != ""){
      jobpayReEntryUrl = process.env.VUE_APP_JOBPAY_MEMBER_SITE_URL_BASE + "/entry/edit/registration?entry=" + encodeURIComponent(user.customerInfo.jobpay_entry_key) + "&email=" + encodeURIComponent(user.customerInfo.jobpay_entry_mail_address);
    }

    const formatISO8601DateTime = (date_string: string) => {
      return displayui.formatISO8601DateTime(date_string);
    };

    onMounted(() => {
      getBankAccount();
    });

    return {
      jobpayMemberLoginUrl,
      jobpayMemberInfoEditUrl,
      jobpayReEntryUrl,
      customerInfo,
      bankAccount,
      dateOfBirth,
      formatISO8601DateTime
    };
  },
});
